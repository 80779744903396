export const commonConstants = {
  inActive: "Inactive",
  active: "Active",
  idle: "Idle",
  running: "Running",
  manual: "Manual",
  alarm: "Alarm",
  event: "Event",
  source: "Source",
  target: "Target",
  destination: "Destination",
};

export const workFlowConstants = {
  typeOfNode: {
    empty: "empty_node",
    start: "start_node",
    middle: "middle_node",
    condition: "condition_node",
    endBottom: "end_bottom_node",
    end: "end_node",
  },
  nodeType: {
    startNode: "start_node",
    emptyNode: "empty_node",
    timerNode: "timer_node",
    conditionalNode: "condition_node",
    emailNode: "email_node",
    ocrNode: "OCR_node",
    googleDriveNode: "google_drive_node",
    googleSheetNode: "google_sheet_node",
    googleCalendarNode: "google_calendar_node",
    googleMailNode: "google_mail_node",
    AWS_S3_Node: "AWS_S3_node",
    postgresSQL_Node: "postgres_node",
    linkedInNode: "linkedIn_node",
    whatsAppNode: "whatsApp_node",
    openAINode: "openAI_node",
    webHookNode: "webHook_node",
    tallyNode: "tally_node",
  },
  nodeLabel: {
    startNode: "Execute Node",
    emptyNode: "Add Node",
    timerNode: "Timer Node",
    conditionalNode: "Condition Node",
    emailNode: "Send Email",
    ocrNode: "OCR",
    googleDriveNode: "Google Drive",
    googleSheetNode: "Google Sheet",
    googleCalendarNode: "Google Calendar",
    googleMailNode: "GMail",
    AWS_S3_Node: "AWS S3",
    postgresSQL_Node: "PostgreSQL",
    linkedInNode: "LinkedIn",
    whatsAppNode: "WhatsApp",
    openAINode: "openAI",
    webHookNode: "Web Hook",
    tallyNode: "Tally",
  },
  state: {
    idle: commonConstants.idle,
    running: commonConstants.running,
  },
  status: {
    inActive: commonConstants.inActive,
    active: commonConstants.active,
  },
  triggeredBy: {
    manual: commonConstants.manual,
    alarm: commonConstants.alarm,
    event: commonConstants.event,
  },
  enumConstants: {
    status: [commonConstants.inActive, commonConstants.active],
    state: [commonConstants.idle, commonConstants.running],
    triggeredBy: [commonConstants.manual, commonConstants.alarm, commonConstants.event],
  },
  default: {
    status: commonConstants.inActive,
    state: commonConstants.idle,
    triggeredBy: commonConstants.manual,
  },
};

export const tokenTypes = {
  googleServices: {
    drive_in: "google-drive-in",
    drive_out: "google-drive-out",
    mail: "google-mail",
    sheet: "google-sheet",
    calender: "google-calender",
  },
};

export const NewNode = [
  {
    id: "node_1",
    type: workFlowConstants.typeOfNode.start,
    position: { x: 100.0, y: 100.0 },
    data: { label: "Start", type: workFlowConstants.nodeType.startNode, config: true },
  },
  {
    width: 125,
    height: 125,
    id: "node_2",
    type: workFlowConstants.typeOfNode.middle,
    position: {
      x: 315.0,
      y: 100.0,
    },
    data: {
      label: workFlowConstants.nodeLabel.timerNode,
      type: workFlowConstants.nodeType.timerNode,
      config: false,
    },
  },
  {
    id: "node_3",
    type: workFlowConstants.typeOfNode.empty,
    position: { x: 530.0, y: 100.0 },
    data: {
      label: workFlowConstants.nodeLabel.emptyNode,
      type: workFlowConstants.nodeType.emptyNode,
      config: false,
    },
  },
];

export const NewEdge = [
  {
    id: "reactflow__edge-node_1a-node_2a",
    source: "node_1",
    target: "node_2",
    sourceHandle: "a",
    targetHandle: "a",
    // type: "smoothstep",
    markerEnd: { type: "arrowclosed", color: "#707070" },
    style: { strokeWidth: 2, stroke: "#707070" },
    deletable: false,
  },
  {
    id: "reactflow__edge-node_2a-node_3a",
    source: "node_2",
    target: "node_3",
    sourceHandle: "a",
    targetHandle: "a",
    // type: "smoothstep",
    markerEnd: { type: "arrowclosed", color: "#707070" },
    style: { strokeWidth: 2, stroke: "#707070" },
  },
];

export const proOptions = { hideAttribution: true };

export const CompanyName = "OpenTurf Technologies";

export const userManagement = "User Management";
export const roleManagement = "Role Management";
export const dashboard = "Dashboard";
export const docketManagement = "Docket Management";
export const labelManagement = "Label Management";
export const Settings = "Settings";

export const CountryList = [
  { code: "AF", name: "Afghanistan" },
  // { code: "AX", name: "Åland Islands" },
  { code: "AX", name: "Aland Islands" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algeria" },
  { code: "AS", name: "American Samoa" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AQ", name: "Antarctica" },
  { code: "AG", name: "Antigua and Barbuda" },
  { code: "AR", name: "Argentina" },
  { code: "AM", name: "Armenia" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahrain" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Belarus" },
  { code: "BE", name: "Belgium" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bhutan" },
  { code: "BO", name: "Bolivia, Plurinational State of" },
  { code: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { code: "BA", name: "Bosnia and Herzegovina" },
  { code: "BW", name: "Botswana" },
  { code: "BV", name: "Bouvet Island" },
  { code: "BR", name: "Brazil" },
  { code: "IO", name: "British Indian Ocean Territory" },
  { code: "BN", name: "Brunei Darussalam" },
  { code: "BG", name: "Bulgaria" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Cambodia" },
  { code: "CM", name: "Cameroon" },
  { code: "CA", name: "Canada" },
  { code: "CV", name: "Cape Verde" },
  { code: "KY", name: "Cayman Islands" },
  { code: "CF", name: "Central African Republic" },
  { code: "TD", name: "Chad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CX", name: "Christmas Island" },
  { code: "CC", name: "Cocos (Keeling) Islands" },
  { code: "CO", name: "Colombia" },
  { code: "KM", name: "Comoros" },
  { code: "CG", name: "Congo" },
  { code: "CD", name: "Congo, the Democratic Republic of the" },
  { code: "CK", name: "Cook Islands" },
  { code: "CR", name: "Costa Rica" },
  { code: "CI", name: "Côte d'Ivoire" },
  { code: "HR", name: "Croatia" },
  { code: "CU", name: "Cuba" },
  { code: "CW", name: "Curaçao" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominican Republic" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Egypt" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Ethiopia" },
  { code: "FK", name: "Falkland Islands (Malvinas)" },
  { code: "FO", name: "Faroe Islands" },
  { code: "FJ", name: "Fiji" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GF", name: "French Guiana" },
  { code: "PF", name: "French Polynesia" },
  { code: "TF", name: "French Southern Territories" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GE", name: "Georgia" },
  { code: "DE", name: "Germany" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Greece" },
  { code: "GL", name: "Greenland" },
  { code: "GD", name: "Grenada" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernsey" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haiti" },
  { code: "HM", name: "Heard Island and McDonald Islands" },
  { code: "VA", name: "Holy See (Vatican City State)" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hong Kong" },
  { code: "HU", name: "Hungary" },
  { code: "IS", name: "Iceland" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "IR", name: "Iran, Islamic Republic of" },
  { code: "IQ", name: "Iraq" },
  { code: "IE", name: "Ireland" },
  { code: "IM", name: "Isle of Man" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italy" },
  { code: "JM", name: "Jamaica" },
  { code: "JP", name: "Japan" },
  { code: "JE", name: "Jersey" },
  { code: "JO", name: "Jordan" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KE", name: "Kenya" },
  { code: "KI", name: "Kiribati" },
  { code: "KP", name: "Korea, Democratic People's Republic of" },
  { code: "KR", name: "Korea, Republic of" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "LA", name: "Lao People's Democratic Republic" },
  { code: "LV", name: "Latvia" },
  { code: "LB", name: "Lebanon" },
  { code: "LS", name: "Lesotho" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libya" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MO", name: "Macao" },
  { code: "MK", name: "Macedonia, the former Yugoslav Republic of" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malawi" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Maldives" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MQ", name: "Martinique" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauritius" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "Mexico" },
  { code: "FM", name: "Micronesia, Federated States of" },
  { code: "MD", name: "Moldova, Republic of" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolia" },
  { code: "ME", name: "Montenegro" },
  { code: "MS", name: "Montserrat" },
  { code: "MA", name: "Morocco" },
  { code: "MZ", name: "Mozambique" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Netherlands" },
  { code: "NC", name: "New Caledonia" },
  { code: "NZ", name: "New Zealand" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NU", name: "Niue" },
  { code: "NF", name: "Norfolk Island" },
  { code: "MP", name: "Northern Mariana Islands" },
  { code: "NO", name: "Norway" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PS", name: "Palestinian Territory, Occupied" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PH", name: "Philippines" },
  { code: "PN", name: "Pitcairn" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "PR", name: "Puerto Rico" },
  { code: "QA", name: "Qatar" },
  { code: "RE", name: "Réunion" },
  { code: "RO", name: "Romania" },
  { code: "RU", name: "Russian Federation" },
  { code: "RW", name: "Rwanda" },
  { code: "BL", name: "Saint Barthélemy" },
  { code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { code: "KN", name: "Saint Kitts and Nevis" },
  { code: "LC", name: "Saint Lucia" },
  { code: "MF", name: "Saint Martin (French part)" },
  { code: "PM", name: "Saint Pierre and Miquelon" },
  { code: "VC", name: "Saint Vincent and the Grenadines" },
  { code: "WS", name: "Samoa" },
  { code: "SM", name: "San Marino" },
  { code: "ST", name: "Sao Tome and Principe" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "SN", name: "Senegal" },
  { code: "RS", name: "Serbia" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapore" },
  { code: "SX", name: "Sint Maarten (Dutch part)" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "SB", name: "Solomon Islands" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "South Africa" },
  { code: "GS", name: "South Georgia and the South Sandwich Islands" },
  { code: "SS", name: "South Sudan" },
  { code: "ES", name: "Spain" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Suriname" },
  { code: "SJ", name: "Svalbard and Jan Mayen" },
  { code: "SZ", name: "Swaziland" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "SY", name: "Syrian Arab Republic" },
  { code: "TW", name: "Taiwan, Province of China" },
  { code: "TJ", name: "Tajikistan" },
  { code: "TZ", name: "Tanzania, United Republic of" },
  { code: "TH", name: "Thailand" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad and Tobago" },
  { code: "TN", name: "Tunisia" },
  { code: "TR", name: "Turkey" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TC", name: "Turks and Caicos Islands" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraine" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "GB", name: "United Kingdom" },
  { code: "US", name: "United States" },
  { code: "UM", name: "United States Minor Outlying Islands" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "VE", name: "Venezuela, Bolivarian Republic of" },
  { code: "VN", name: "Viet Nam" },
  { code: "VG", name: "Virgin Islands, British" },
  { code: "VI", name: "Virgin Islands, U.S." },
  { code: "WF", name: "Wallis and Futuna" },
  { code: "EH", name: "Western Sahara" },
  { code: "YE", name: "Yemen" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },
];

export const accessDeniedMsg = "Access denied, please contact admin";

export const USER_ROLES = {
  enterpriseAdmin: "Enterprise Admin",
  admin: "Admin",
  approver: "Approver",
  user: "User",
};

export const INVOICE_STATUS = {
  PAID: "PAID",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  REVIEWED: "REVIEWED",
  CREATED: "CREATED",
};

export const edit_mode = {
  edit: "verify",
  classify: "classify",
  summary: "summary",
};

// PAN = /^[A-Z]{3}[CPHFATBLJG][A-Z][0-9]{4}[A-Z]$/;
export const PAN_NUMBER_FORMAT = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
export const GSTIN_NUMBER_FORMAT =
  /^[0-3][0-9][A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]{1}Z[0-9A-Z]{1}$/;

export const document_types = {
  // Accounting: {
  invoice: { label: "Invoice", value: "Invoice Details", isDisabled: false },
  bankStatement: {
    label: "Bank Statement",
    value: "Bank Statement",
    isDisabled: false,
  },
  // },
  financialStatement: {
    label: "Financial Statement",
    value: "Financial Statement",
  }
};

export const document_routes = {
  upload: "/app/doc/upload",
  invoice: {
    edit: "/app/invoice/verify/:id",
    classify: "/app/invoice/classify/:id",
    summary: "/app/invoice/summary/:id",
  },
  bankStatement: {
    edit: "/app/statement/verify/:id",
    classify: "/app/statement/classify/:id",
    summary: "/app/statement/summary/:id",
  },
  financialStatement: {
    edit: "/app/financial-statement/edit/:id",
    summary: "/app/financial-statement/summary/:id",
  }
};

export const getDocRoutes = (doc, mode, jobId) => {
  switch (doc) {
    case document_types.invoice.label:
    case document_types.invoice.value:
    case "invoice":
      return document_routes.invoice[mode].replace(":id", jobId);
    case document_types.bankStatement.label:
    case document_types.bankStatement.value:
    case "bankStatement":
      return document_routes.bankStatement[mode].replace(":id", jobId);
      case document_types.financialStatement.label:
        case document_types.financialStatement.value:
        case "financialStatement":
          return document_routes.financialStatement[mode].replace(":id", jobId);
    default:
      return new Error("Not a valid Document Type");
  }
};

export const ticket_status = {
  all: "All",
  open: "Open",
  awaitingInformation: "Awaiting information",
  unableToConnect: "Unable to connect",
  needMoreInfo: "Need more info",
  pending: "Pending",
  resolved: "Resolved",
  reopen: "Reopen",
};

export const ticket_communication_mode = {
  email: "Email",
  phone: "Phone",
  inPerson: "In person",
};

export const service_type = {
  accounting: "Accounting",
  lending: "Lending",
};

export const form_types = {
  table: "Table",
  form: "Form",
  fieldArray: "fieldArray",
};

export const field_types = {
  index: "index",
  select: "select",
  text: "text",
  number: "number",
  date: "date",
  textarea: "textarea",
  email: "email",
  checkbox: "checkbox",
  password: "password",
  radio: "radio",
  file: "file",
  range: "range",
  color: "color",
  daterange: "daterange",
  amount: "amount",
  custom_ref_pop: "custom_ref_pop",
  custom_status: "custom_status",
  custom_element: "custom_element",
};

export const document_visibility = {
  halfScreen: "Half-Screen",
  floating: "Floating",
  hidden: "Hidden",
};

export const integration_types = {
  tally: "TallyPrime",
  quickBook: "QuickBook",
  zohoBooks: "ZohoBooks",
  exportToCSV: "ExportToCSV",
};

export const wrapper_component_types = {
  collapse: "Collapse",
  accordion: "Accordion",
  div: "Div",
  default: "Div",
};


export const tax_field_options = {
  SGST: [
    { label: "0%", value: 0 },
    { label: "2.5%", value: 2.5 },
    { label: "6%", value: 6 },
    { label: "9%", value: 9 },
    { label: "14%", value: 14 },
  ],
  CGST: [
    { label: "0%", value: 0 },
    { label: "2.5%", value: 2.5 },
    { label: "6%", value: 6 },
    { label: "9%", value: 9 },
    { label: "14%", value: 14 },
  ],
  IGST: [
    { label: "0%", value: 0 },
    { label: "5%", value: 5 },
    { label: "12%", value: 12 },
    { label: "18%", value: 18 },
    { label: "28%", value: 28 },
  ],
  TDS: [
    { label: "0%", value: 0 },
    { label: "1%", value: 1 },
    { label: "2%", value: 2 },
    { label: "5%", value: 5 },
    { label: "10%", value: 10 },
  ],
  TCS: [
    { label: "0%", value: 0 },
    { label: "0.075%", value: 0.075 },
    { label: "0.1%", value: 0.1 },
    { label: "0.75%", value: 0.75 },
    { label: "1%", value: 1 },
    { label: "2%", value: 2 },
    { label: "2.5%", value: 2.5 },
    { label: "5%", value: 5 },
  ],
  VAT: [
    { label: "0%", value: 0 },
    { label: "5%", value: 5 },
    { label: "7.7%", value: 7.7 },
  ],
  // TDS: [
  //   { label: "194C", value: "194C" },
  //   { label: "194H", value: "194H" },
  //   { label: "194-I", value: "194-I" },
  //   { label: "194J", value: "194J" },
  // ],
};

export const config_integration = {
  export_as_csv: integration_types.exportToCSV,
  tally_connector: integration_types.tally,
  tally_enabled: integration_types.tally,
  quick_book: integration_types.quickBook,
  quickbook_enabled: integration_types.quickBook,
  zohobook_enabled: integration_types.zohoBooks,
};

export const fsa_template = {
  cashFlow: {
    years: [],
    operating_activities: {
      profit_before_tax: [],
      non_cash_expenses: [],
      changes_in_working_captial: [],
      cash_generated_from_operations: [],
      income_tax: [],
      net_cash_flow_from_operating_activities: [],
    },
    investing_activities: [],
    financing_activities: [],
    cash_position: {
      beginning_of_year: [],
      change_during_year: [],
      end_of_year: [],
    },
  },
  balanceSheet: {
    years: [],
    non_current_assets: [],
    current_assets: [],
    total_assets: [],
    equity: [],
    non_current_liabilities: [],
    current_liabilities: [],
    other_liabilities: [],
    total_liabilities: [],
    total_equity_and_liabilities: [],
  },
  profit_and_loss: {
    years: [],
    revenues: [],
    expenses: [],
    profit_before_tax: [],
    tax_expenses: [],
    profit_after_tax: [],
  },
  company_overview: {
    company_name: "",
    financial_years: [],
    nature_of_business: "",
    key_personnels: [""],
    company_classification: "",
    auditor_name: "",
    currency_code: "",
  },
};

export const APP_ROUTES_LIST = {
  creditAssessment: {
    path: "/app/credit-assessment",
    icon: "ClipboardListIcon",
    name: "Credit Assessment",
  },
  camReport: {
    path: "/app/cam-report",
    icon: "CamReportIcon",
    name: "CAM Report",
  },
  cibil: {
    path: "/app/cibil",
    icon: "CompanyIcon",
    name: "CIBIL",
  },
  kyc: {
    path: "/app/kyc",
    icon: "UserCheckIcon",
    name: "KYC Documents",
  },
  bookkeeping: {
    path: "/app/bookkeeping",
    icon: "FormsIcon",
    name: "Bookkeeping",
  },
  documentLibrary: {
    path: "/app/document-library",
    icon: "FAQIcon",
    name: "Compliance Bot",
  },
  otherFeatures: {
    path: "/app/other-features",
    icon: "CardsIcon",
    name: "Other Features",
  },
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
