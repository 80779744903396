import React, { useState, useMemo, useEffect, useCallback } from 'react'
import moment from 'moment'
import axios from "axios";
import ThemedSuspense from "../components/ThemedSuspense";
import { urlBase } from "../routes/api-urls";
import { APP_ROUTES_LIST, service_type } from "../utils/constants";

const apiUrl = urlBase;

// create context
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [notify, setNotify] = useState(null);
  const [sideBarRoutes, setSideBarRoutes] = useState([]);
  const [accessToken, setAccessToken] = useState(null);

  const faqRoutes = {
    path: "/app/faq",
    icon: "FAQIcon",
    name: "FAQ",
  };

  const handleAuthResponse = ({ data }) => {
    setAccessToken(data.token);
    setUser(data.user);
    setNotify(data.notify);

    if (data.user.userGroup.serviceType === service_type.lending) {
      const { scopes = [] } = data.user;
      if (scopes?.length > 0) {
        const sideNav = scopes.map((s) => APP_ROUTES_LIST?.[s]).filter((s) => s);
        setSideBarRoutes(sideNav);
      } else {
        setSideBarRoutes(Object.values(APP_ROUTES_LIST));
      }
    } else {
      const sideNav = [...data.user.role.sideNav];
      sideNav.push(faqRoutes);
      setSideBarRoutes(sideNav);
    }

    return { data };
  };

  const refreshTokens = useCallback(() => {
    return axios
      .post(`${apiUrl}/v1/auth/refresh-tokens`, {})
      .then(handleAuthResponse)
      .catch((error) => {
        setUser(null);
        setAccessToken(null);
        setNotify(null);
        setSideBarRoutes([]);
        return error;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startSilentRefresh = useCallback(() => {
    if (accessToken) {
      const tokenExpires = moment(accessToken.expires);
      const tokenMaxAge = tokenExpires.diff(moment().add(1, "minutes"));
      setTimeout(() => {
        refreshTokens();
      }, tokenMaxAge);
    }
  }, [accessToken, refreshTokens]);

  const syncLogout = (event) => {
    if (event.key === "logout") {
      setAccessToken(null);
      setUser(null);
    }
  };

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.credentials = "include";
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [accessToken]);

  useEffect(() => {
    refreshTokens().then((response) => {
      setLoaded(true);
    });
  }, [refreshTokens]);

  useEffect(() => {
    startSilentRefresh();
  }, [accessToken, startSilentRefresh]);

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return function cleanup() {
      window.removeEventListener("storage", syncLogout);
    };
  }, []);

  const value = useMemo(() => {
    const register = (name, email, password, phoneNumber, serviceType) => {
      return axios.post(`${apiUrl}/v1/auth/register`, {
        name,
        email,
        password,
        phoneNumber,
        serviceType,
      });
    };

    const googleRegister = (response) => {
      return axios
        .post(`${apiUrl}/v1/auth/google-register`, {
          token: response.credential,
        })
        .then(handleAuthResponse)
        .then(startSilentRefresh);
    };

    const login = (email, password) => {
      return axios
        .post(`${apiUrl}/v1/auth/login`, {
          email: email,
          password: password,
        })
        .then(handleAuthResponse)
        .then(startSilentRefresh);
    };
    const googleLogin = (response) => {
      return axios
        .post(`${apiUrl}/v1/auth/google-login`, {
          token: response.credential,
        })
        .then(handleAuthResponse)
        .then(startSilentRefresh);
    };

    const otpVerification = async ({ payload, id }) => {
      var URI = `${urlBase}/v1/jobs/otpverify/${id}`;

      return await axios({
        method: "POST",
        url: URI,
        data: payload,
      }).then((response) => {
        setAccessToken(response.data.token.access);
        startSilentRefresh();
      });
    };

    const logout = () => {
      setAccessToken(null);
      setUser(null);
      return axios
        .post(`${apiUrl}/v1/auth/logout`, {})
        .then((response) => {
          window.localStorage.setItem("logout", moment());
        })
        .catch((err) => {});
    };

    const forgotPassword = (email) => {
      return axios.post(`${apiUrl}/v1/auth/forgot-password`, {
        email: email,
      });
    };

    const resetPassword = (password, resetToken) => {
      return axios.post(`${apiUrl}/v1/auth/reset-password?token=${resetToken}`, {
        password: password,
      });
    };

    const verifyEmail = (emailVerificationToken) => {
      return axios.post(
        `${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`,
        {}
      );
    };

    // get role permissions
    const getRolePermissions = (e, action) => {
      if (e === undefined || e === null) return false;

      let permission = [
        { index: 0, arr: ["view"] },
        { index: 1, arr: ["view", "update"] },
        { index: 2, arr: ["view", "delete"] },
        { index: 3, arr: ["view", "update", "delete"] },
        { index: 4, arr: ["view", "create"] },
        { index: 5, arr: ["view", "create", "update"] },
        { index: 6, arr: ["view", "create", "delete"] },
        { index: 7, arr: ["view", "create", "update", "delete"] },
        { index: 8, arr: ["view", "merge"] },
        { index: 9, arr: ["view", "update", "merge"] },
        { index: 10, arr: ["view", "delete", "merge"] },
        { index: 11, arr: ["view", "update", "delete", "merge"] },
        { index: 12, arr: ["view", "create", "merge"] },
        { index: 13, arr: ["view", "create", "update", "merge"] },
        { index: 14, arr: ["view", "create", "delete", "merge"] },
        { index: 15, arr: ["view", "create", "update", "delete", "merge"] },
      ];
      if (e === 15) return true;
      if (permission[e].arr.indexOf(action) >= 0) return true;

      return false;
    };

    // check role permissions
    const checkTheRole = (name, action = "view") => {
      let defaultSidebar = ["Profile", "Billing", "Logout"];
      if (user === null || user === undefined) return true;
      if (!user?.role?.resources) {
        if (defaultSidebar.find((f) => f === name)) {
          return true;
        }
        return false;
      }

      let val = true;
      let ind = Object.keys(user?.role?.resources);
      let index = ind?.reverse()?.indexOf(name);
      let sendIndex = Object.values(user?.role?.resources)?.reverse()[index];

      val = val && getRolePermissions(sendIndex, action);
      return defaultSidebar.indexOf(name) >= 0 ? true : val;
    };

    return {
      user,
      setUser,
      register,
      googleRegister,
      login,
      googleLogin,
      logout,
      forgotPassword,
      resetPassword,
      verifyEmail,
      refreshTokens,
      checkTheRole,
      otpVerification,
      notify,
      sideBarRoutes,
    };
    // eslint-disable-next-line
  }, [user, startSilentRefresh, refreshTokens, notify, sideBarRoutes]);

  if (!isLoaded) {
    return <ThemedSuspense />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
